import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Image from "../ImageQuerys/GiftCardImages";
// import TestmonialReviews from '../TestimonialReviews/TestmonialReviews';
// import CtaPrimary from '../Buttons/primary-cta';
import { viewports, colors } from "../../style-vars";
import arrow from "../../images/svg/long-right-arrow.svg";
import arrowUp from "../../images/svg/arrows_up.svg";

const { Title } = Typography;
const { smOrSmaller, mdOrSmaller, mdOnly } = viewports;

const GiftCards = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { sectionData, carouselData, sectionNavId } = props;
  const {
    blockTitle,
    blockSubTitle,
    blockList,
    blockImg,
    // ctaInfoSecondary,
  } = sectionData;
  // const { ctaTitle, ctaTarget } = ctaInfoSecondary;
  return (
    <section className="gift-cards" id={sectionNavId}>
      <Row>
        <Col lg={12} sm={24} xs={24}>
          <div>
            <Title id="gift-cards" level={2}>
              {blockTitle}
              <span className="blue_dot">.</span>{" "}
            </Title>
            <p style={{ marginBottom: 40 }}>{blockSubTitle}</p>
            <Row gutter={[16, 16]}>
              {blockList.map((item) => (
                <Col key={item} lg={12} sm={24} xs={24}>
                  <div className="sub-grid">
                    <img className="gift-cards__arrow" src={arrow} alt="" />
                    <span>{item}</span>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="gift-cards__floating-card">
              <div>
                <span>Extra spend</span>
                <p>by gift card holders</p>
              </div>
              <div>
                <span>38%</span>
                <img src={arrowUp} alt="" />
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} sm={24} xs={24}>
          <Image imageName={blockImg} className="gift-cards__main-img" />
        </Col>
      </Row>
      {/* <TestmonialReviews
        sectionData={carouselData}
        className="gift-cards__car"
      /> */}
      {/* <CtaPrimary
        target={ctaTarget}
        ctaTitle={ctaTitle}
        className="get_e-cards"
      /> */}

      <style jsx global>{`
        .gift-cards {
          position: relative;
          padding: 32px 80px;
        }

        .gift-cards h2 {
          font-weight: 800 !important;
          margin-bottom: 24px !important;
        }

        .gift-cards span {
          display: inline-block !important;
          font-size: 16px !important;
          color: ${colors.black} !important;
          font-weight: 700;
          line-height: 1.5 !important;
          transform: translateY(-10px) !important;
        }

        .gift-cards p {
          font-size: 16px;
          margin: 0px;
          color: ${colors.black200};
          line-height: 1.8;
        }

        .gift-cards__arrow {
          display: inline-block;
        }

        .gift-cards__car {
          margin-top: 30px;
          left: 0px;
          width: 100% !important;
        }

        .gift-cards__main-img {
          transform: translateY(80px);
        }

        .gift-cards__floating-card {
          padding: 32px;
          position: absolute;
          left: 50%;
          top: 77%;
          z-index: 2;
          border-radius: 13px;
          max-width: 650px;
          width: 100%;
          background-color: ${colors.white};
          box-shadow: 0 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
          display: grid;
          grid-template-columns: 70% 30%;
        }

        .gift-cards__floating-card span {
          font-size: 24px !important;
          font-weight: 800 !important;
          margin-bottom: 0 !important;
        }

        .gift-cards__floating-card p {
          color: ${colors.black200};
          font-size: 16px;
          margin-bottom: 0 !important;
          width: 100%;
        }

        .gift-cards__floating-card span {
          color: #00ab4f;
          font-weight: 800;
          font-size: 42px;
          margin-right: 12px;
        }

        .gift-cards__floating-card img {
          width: 32px;
          transform: translateY(-12px);
        }

        .get_e-cards {
          margin: auto;
        }

        .sub-grid {
          display: grid;
          grid-template-columns: 10% 80%;
          grid-gap: 12px;
        }

        @media ${mdOnly} {
          .gift-cards {
            padding: 32px 100px;
            margin-bottom: 0;
          }

          .gift-cards p {
            width: 100%;
          }
        }

        @media ${mdOrSmaller} {
          .gift-cards__car {
            margin-top: -20px;
            position: relative;
            left: 0;
            bottom: 0;
          }

          .gift-cards__floating-card {
            position: relative;
            left: 0;
            padding: 24px 12px;
            grid-template-columns: 60% 40%;
          }

          .gift-cards__floating-card span {
            font-size: 32px;
          }

          .gift-cards__floating-card img {
            width: 18px;
          }
        }

        @media ${smOrSmaller} {
          .gift-cards {
            padding: 10px;
          }
        }
      `}</style>
    </section>
  );
};

GiftCards.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  sectionNavId: PropTypes.string,
  carouselData: PropTypes.objectOf(PropTypes.any),
};
GiftCards.defaultProps = {
  sectionData: {},
  sectionNavId: "",
  carouselData: {},
};

export default GiftCards;
